import { gunzipSync } from 'fflate';

/**
 * Decompresses a gzip file from a URL
 * @param {string} url - The URL of the gzip file to decompress
 * @returns {Promise<Uint8Array>} - The decompressed data as a Uint8Array
 */
export async function decompressGzipFromUrl(url) {
    let compressedData;
    try {
        // Fetch the compressed file
        const response = await fetch(url);
        const compressedBuffer = await response.arrayBuffer();
        compressedData = new Uint8Array(compressedBuffer);

        // Check for gzip magic number (should start with 0x1F 0x8B)
        if (compressedData[0] !== 0x1F || compressedData[1] !== 0x8B) {
            throw new Error('Not a valid gzip file (incorrect magic number)');
        }

        // Decompress the data using fflate
        const decompressedData = gunzipSync(compressedData);
        console.log('Decompressed data length:', decompressedData.length);

        return decompressedData;
    } catch (error) {
        console.error('Error decompressing gzip file:', error);
        if (compressedData) {
            console.log('First few bytes:', Array.from(compressedData.slice(0, 10)));
        }
        throw error;
    }
}
