<template>
  <OnboardingPage v-if="user.onboarded_steps_completed < 5" />
  <div v-else class="min-h-full flex relative">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-900">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <router-link to="/" class="flex-shrink-0 flex items-center px-4 cursor-pointer">
                <img class="h-8 w-auto" :src="Logo" alt="Refrag Logo" />
              </router-link>
              <nav class="mt-5 px-2 space-y-1">
                <router-link
                  v-for="item in navigation.filter((navItem) => !navItem.disabled)"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-base font-light rounded-md',
                  ]"
                >
                  <img
                    :src="item.icon"
                    class="mr-4 flex-shrink-0 h-6 w-6 gameIcon"
                    :class="[
                      item.current
                      ? 'brightness-200'
                      : 'brightness-100']"
                        v-if="item.customIcon"
                  />
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                    v-else
                  />
                  {{ item.name }}
                </router-link>
                <a
                  v-for="item in externalNavigation"
                  :key="item.name"
                  :href="item.href"
                  target="_blank"
                  :class="[
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-base font-light rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Collapsed sidebar for desktop -->
    <div
      v-if="iconsOnly"
      @mouseover="sideBarExtended = true"
      @mouseleave="sideBarExtended = false"
      class="flex"
    >
      <div
        class="md:w-16 md:hover:w-[210px] grow-transition hidden md:flex md:flex-col md:fixed md:inset-y-0 z-20"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex-1 flex flex-col min-h-0 bg-gray-900 boxshadow-r">
          <div class="flex-1 flex flex-col pt-6 overflow-y-auto overflow-x-hidden">
            <router-link to="/" class="flex items-center pb-12 pl-6">
              <img v-if="sideBarExtended" class="min-w-[152px] overflow-hidden" :src="Logo" alt="Refrag Logo" />
              <img v-else class="h-6" src="../../assets/logos/refrag-icon.svg" alt="Refrag Logo" />
            </router-link>
            <div class="text-xs text-text-secondary uppercase tracking-[1.5px] pl-6"
            :class="[sideBarExtended ? 'pb-2.5' : 'pb-[26px]']">
              <span v-if="sideBarExtended">Overview</span>
            </div>
            <nav class="flex-1 space-y-1">
              <router-link
                v-for="item in navigation.filter((navItem) => !navItem.disabled)"
                :key="item.name"
                :to="getRoute(item)"
                :class="[
                  item.current
                    ? 'bg-gray-800 selected-nav text-text-primary'
                    : 'hover:bg-gray-700 text-text-secondary',
                  'group flex items-center py-2 text-sm  font-medium rounded-r-md mr-4 whitespace-nowrap',
                ]"
              >
                <img
                  :src="item.icon"
                  class="mr-3 ml-6 flex-shrink-0 h-5 w-5"
                  :class="[
                  item.current
                  ? 'brightness-200'
                  : 'brightness-100']"
                  v-if="item.customIcon"
                />
                <component
                  :is="item.icon"
                  class="mr-3 ml-6 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                  v-else
                />
                <span v-if="sideBarExtended" class="flex-1">{{ item.name }}</span>
                <Pill class="px-1 py-[1px] mr-1" v-if="item.showUpgrade && sideBarExtended" text="Upgrade" bgColor="#EF6B6B20" textColor="#EF6B6B" />
                <Pill class="px-1 py-[1px] mr-1" v-if="item.beta && sideBarExtended && !item.showUpgrade" text="Beta" bgColor="#D071C520" textColor="#D071C5" />
                <Pill class="px-1 py-[1px] mr-1" v-if="item.new && sideBarExtended && !item.showUpgrade" text="New" bgColor="#14B8A620" textColor="#14B8A6" />
              </router-link>
              <a
                v-for="item in externalNavigation"
                :key="item.name"
                :href="item.disabled ? '#' : item.href"
                target="_blank"
                :class="[
                  item.current
                  ? 'bg-gray-800 border-l-2 border-primary-600 text-text-primary'
                  : 'hover:bg-gray-700 text-text-secondary',
                    'group flex items-center py-2 text-sm text-text-primary font-medium rounded-r-md mr-4 whitespace-nowrap',
                ]"
              >
                <component
                  :is="item.icon"
                  class="mr-3 ml-6 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span v-if="sideBarExtended">{{ item.name }}</span>
              </a>
            </nav>
          </div>
          <div class="flex-shrink-0 flex border-t border-gray-700 py-6"
            :class="[sideBarExtended ? 'mx-6' : 'mx-2']">
            <Menu as="div" class="relative inline-block text-left w-full">
              <div>
                <MenuButton
                  class="group w-full rounded-md text-left text-sm font-medium text-gray-700 hover:bg-[#131A20] focus:outline-none focus:ring-0"
                  :class="[sideBarExtended ? 'px-3.5 py-2 border border-gray-700 bg-gray-800' : '']"
                  >
                  <span class="flex w-full items-center"
                    :class="[sideBarExtended ? 'justify-between' : 'justify-center']">
                    <span class="flex min-w-0 items-center justify-between space-x-3">
                      <img
                        class="h-6 w-6 flex-shrink-0 rounded-full"
                        :src="activeTeam.logo || buttonLogo"
                        alt=""
                      />
                      <span v-if="sideBarExtended" class="flex min-w-0 flex-1 flex-col">
                        <span class="truncate text-sm font-medium text-text-secondary">{{
                          activeTeam.name
                        }}</span>
                      </span>
                    </span>
                    <img
                      v-if="sideBarExtended"
                      src="../../assets/icons/dropdown.svg"
                      class="flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="-top-2 transform -translate-y-full absolute right-0 w-full origin-top-right divide-y divide-gray-100 rounded-md border border-gray-700 shadow-lg outline-none bg-gray-900"
                >
                  <div class="py-1">
                    <MenuItem v-for="team in user.teams" :key="team.id">
                      <a
                        href="#"
                        @click="updateActiveTeam(team)"
                        :class="[
                          activeTeam.id == team.id
                            ? 'text-text-primary bg-gray-700'
                            : 'text-text-secondary bg-gray-900 hover:text-text-primary hover:bg-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >{{ team.name }}</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div v-else class="hidden md:flex md:w-[210px] md:flex-col md:fixed md:inset-y-0 z-20">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-gray-900 boxshadow-r">
        <div class="flex-1 flex flex-col pt-6 overflow-y-auto">
          <router-link to="/" class="flex items-center flex-shrink-0 pb-12 pl-6">
            <img class="w-[152px]" :src="Logo" alt="Refrag Logo" />
          </router-link>
          <div class="text-xs text-text-secondary uppercase pb-2.5 tracking-[1.5px] pl-6">
            Overview
          </div>
          <nav class="flex-1 space-y-1">
            <div class="flex flex-col gap-2" v-for="item in navigation.filter((navItem) => !navItem.disabled)">
              <router-link
                :key="item.name"
                :to="getRoute(item)"
                :class="[
                  item.current
                    ? 'bg-gray-800 selected-nav text-text-primary'
                    : 'hover:bg-gray-700 text-text-secondary',
                  'group flex items-center py-2 text-sm  font-medium rounded-r-md mr-4 whitespace-nowrap',
                ]"
              >
                <img
                  :src="item.icon"
                  class="mr-3 ml-6 flex-shrink-0 h-5 w-5"
                  :class="[
                  item.current
                  ? 'brightness-200'
                  : 'brightness-100']"
                  v-if="item.customIcon"
                />
                <component
                  :is="item.icon"
                  class="mr-3 ml-6 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                  v-else
                />
                <p class="flex-1">{{ item.name }}</p>
                <Pill class="px-1 py-[1px] mr-1" v-if="item.showUpgrade" text="Upgrade" bgColor="#EF6B6B20" textColor="#EF6B6B" />
                <Pill class="px-1 py-[1px] mr-1" v-if="item.beta && !item.showUpgrade" text="Beta" bgColor="#D071C520" textColor="#D071C5" />
                <Pill class="px-1 py-[1px] mr-1" v-if="item.new && !item.showUpgrade" text="New" bgColor="#14B8A620" textColor="#14B8A6" />
              </router-link>
              <div v-if="item.subMenu && item.current" class="w-full px-8 flex gap-4">
                <div class="w-[2px] bg-gray-800">
                  <div class="w-[2px] h-6 bg-primary-600 transition-all duration-300"
                    :style="{ marginTop: subMenuSliderMargin || 0 }">
                  </div>
                </div>
                <div class="flex flex-col flex-1 gap-2">
                  <router-link v-for="subItem in item.subMenu" :to="subItem.href" class="w-full py-1 flex items-center text-xs"
                    :class="[subItem.current ? 'text-text-primary' : 'text-text-secondary']">
                    {{ subItem.name }}
                  </router-link>
                </div>
              </div>
            </div>
            <a
              v-for="item in externalNavigation"
              :key="item.name"
              :href="item.disabled ? '#' : item.href"
              target="_blank"
              :class="[
                item.current
                  ? 'bg-gray-800 border-l-2 border-primary-600 text-text-primary'
                  : 'hover:bg-gray-700 text-text-secondary',
                  'group flex items-center py-2 text-sm text-text-primary font-medium rounded-r-md mr-4 whitespace-nowrap',
              ]"
            >
              <component
                :is="item.icon"
                class="mr-3 ml-6 flex-shrink-0 h-5 w-5"
                aria-hidden="true"
              />
              {{ item.name }}
            </a>
          </nav>
        </div>
        <div class="flex-shrink-0 flex border-t border-gray-700 py-6 mx-6">
          <Menu as="div" class="relative inline-block text-left w-full">
            <div>
              <MenuButton
                class="group w-full rounded-md border border-gray-700 bg-gray-800 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-[#131A20] focus:outline-none focus:ring-0"
              >
                <span class="flex w-full items-center justify-between">
                  <span class="flex min-w-0 items-center justify-between space-x-3">
                    <img
                      class="h-6 w-6 flex-shrink-0 rounded-full bg-gray-300"
                      :src="activeTeam.logo || buttonLogo"
                      alt=""
                    />
                    <span class="flex min-w-0 flex-1 flex-col">
                      <span class="truncate text-sm font-medium text-text-secondary">{{
                        activeTeam.name
                      }}</span>
                    </span>
                  </span>
                  <img
                    src="../../assets/icons/dropdown.svg"
                    class="flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="-top-2 transform -translate-y-full absolute right-0 w-full origin-top-right divide-y divide-gray-100 rounded-md border border-gray-700 shadow-lg outline-none bg-gray-900"
              >
                <div class="py-1">
                  <MenuItem v-for="team in user.teams" :key="team.id">
                    <a
                      href="#"
                      @click="updateActiveTeam(team)"
                      :class="[
                        activeTeam.id == team.id
                          ? 'text-text-primary bg-gray-700'
                          : 'text-text-secondary bg-gray-900 hover:text-text-primary hover:bg-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      >{{ team.name }}</a
                    >
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <div :class="[iconsOnly ? 'md:pl-16' : 'md:pl-52', 'flex flex-col min-w-full flex-1 min-h-full']">
      <main class="h-full flex flex-col bg-gray-950">
        <NavBar v-bind:toggleMobileMenu="toggleMobileMenu" :user="user" @openUpgradeModal="showUpgradeModal = true"/>

        <div v-if="noPadding" class="flex-1 flex z-0">
            <!-- Replace with your content -->
            <div class="flex flex-1" v-if="$auth.ready()">
              <AlertPopup />
              <slot></slot>
            </div>
            <div v-else>loading</div>
            <!-- /End replace -->
        </div>

        <div v-else class="flex-1 z-0">
          <div class="mx-auto h-full">
            <!-- Replace with your content -->
            <div class="h-full p-4 md:p-12" v-if="$auth.ready()">
              <AlertPopup />
              <slot></slot>
            </div>
            <div v-else>loading</div>
            <!-- /End replace -->
          </div>
        </div>

      </main>
    </div>
    <div v-if="showNotifications" class="flex items-center justify-center w-full h-full fixed inset-y-0 bg-black/60 z-50">
      <NotificationsPopup :data="notifications[0]" @close="seeNotification"/>
    </div>
    <SubscribeModal
      :show="showUpgradeModal"
      @closeModal="showUpgradeModal = false"
    />
  </div>

</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuItem,
  MenuButton,
  MenuItems,
} from "@headlessui/vue";
import {
  Bars3Icon,
  ShoppingCartIcon,
  XMarkIcon,
  CreditCardIcon,
  ArrowRightOnRectangleIcon,
  VideoCameraIcon,
  UserIcon,
  BookOpenIcon,
  FilmIcon,
  Bars4Icon,
  CloudIcon,
  ChartBarIcon,
  XCircleIcon,
  LifebuoyIcon,
  CogIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  AcademicCapIcon,
  UserGroupIcon,
  ChevronUpIcon,
  StarIcon,
  PlayIcon,
} from "@heroicons/vue/20/solid";
import NavBar from "./NavBar.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, computed, watch, getCurrentInstance, onMounted } from "vue";
import NotificationsPopup from "../ImportantNotificationPopupModal.vue"
import Logo from "../../assets/logos/refrag-horz-fill.svg";
import buttonLogo from "../../assets/logos/refrag-icon-button.svg";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import AlertPopup from "../AlertPopup.vue";
import RestratIcon from "../../assets/icons/restrat.svg";
import HomeIcon from "../../assets/icons/home.svg";
import GlobeAmericasIcon from "../../assets/icons/globe.svg";
import UtilityIcon from "../../assets/icons/utility.svg";
import LeaderboardIcon from "../../assets/icons/leaderboard.svg";
import ServerIcon from "../../assets/icons/server.svg";
import { useStore } from "vuex";
import SteamConnectModal from "../SteamConnectModal.vue";
import axios from 'axios';
import SmokeIcon from "../../assets/icons/smoke.svg";
import Pill from "../Pill.vue";
import OnboardingPage from "../../views/OnboardingPage.vue";
import BootcampIcon from "../../assets/icons/bootcamp.svg";
import RoutinesIcon from "../../assets/icons/routines.svg";
import ModalWindow from "../ModalWindow.vue";
import SubscribeModal from "../SubscribeModal.vue";
import { useHead } from "@unhead/vue";
import { useGtm } from "@gtm-support/vue-gtm";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    CreditCardIcon,
    Bars3Icon,
    XMarkIcon,
    NavBar,
    ArrowRightOnRectangleIcon,
    AlertPopup,
    VideoCameraIcon,
    UserIcon,
    BookOpenIcon,
    FilmIcon,
    Bars4Icon,
    ChartBarIcon,
    XCircleIcon,
    ChevronDownIcon,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    buttonLogo,
    SteamConnectModal,
    AcademicCapIcon,
    ChevronUpIcon,
    StarIcon,
    SmokeIcon,
    Pill,
    OnboardingPage,
    NotificationsPopup,
    RoutinesIcon,
    ModalWindow,
    SubscribeModal
},
  setup() {
    const auth = useAuth();
    const sidebarOpen = ref(false);
    const route = useRoute();
    const routeName = computed(() => route.name);
    const routePath = computed(() => route.path);
    const store = useStore();
    const sideBarExtended = ref(false);
    const activeTeam = ref();
    const showAccountNav = ref(false);
    const selectedGame = ref(store.state.selectedGame);
    const subMenuSliderMargin = ref(0);
    const notifications = ref([]);
    const showNotifications = ref(false);
    const showUpgradeModal = ref(false);
    const gtm = useGtm();
    const { proxy } = getCurrentInstance();
    const navigation = ref([
      {
        name: "Dashboard",
        href: "/",
        icon: HomeIcon,
        current: routeName.value == "Dashboard",
        customIcon: true
      },
      {
        name: "Routines",
        href: "/routines",
        icon: RoutinesIcon,
        new: auth.user().access_level > 0,
        current: routeName.value == "RoutinesPage",
        customIcon: true,
        showUpgrade: auth.user().access_level < 1,
      },
      {
        name: "Upgrade",
        href: "/upgrade",
        icon: CreditCardIcon,
        current: routeName.value == "UpgradePage",
        disabled: auth.user().access_level > 2,
      },
      {
        name: "Demo Review",
        href: "/demos",
        icon: RestratIcon,
        current: routeName.value == "Demos",
        disabled: auth.user().access_level < 2,
        beta: true,
        customIcon: true,
      },
      {
        name: "Demo Review",
        href: "/demo_viewer_upgrade",
        icon: RestratIcon,
        current: routeName.value == "DemoViewerUpgrade",
        disabled: auth.user().access_level > 1,
        showUpgrade: true,
        customIcon: true,
      },
      {
        name: "Community Hub",
        href: "/community_hub/custom_sets",
        icon: GlobeAmericasIcon,
        current: routeName.value == "CommunityHubPage",
        customIcon: true,
        subMenu: [
          {
            name: "Creator Studio",
            href: "/community_hub/custom_sets",
            current: routePath.value == "/community_hub/custom_sets",
          },
          {
            name: "NADR Grenade Groups",
            href: "/community_hub/grenade_groups",
            current: routePath.value == "/community_hub/grenade_groups",
          },
          {
            name: "NADR Strategies",
            href: "/community_hub/strategies",
            current: routePath.value == "/community_hub/strategies",
          },
          {
            name: "VPL",
            href: "/community_hub/vpl",
            current: routePath.value == "/community_hub/vpl",
          },
        ]
      },
      {
        name: "Utility Hub",
        href: "/utility_hub",
        icon: UtilityIcon,
        current: routeName.value == "UtilityHubPage",
        showUpgrade: auth.user().access_level < 2,
        customIcon: true
      },
      {
        name: "Leaderboards",
        href: "/leaderboards",
        icon: LeaderboardIcon,
        current: routeName.value == "Leaderboards",
        customIcon: true
      },
      {
        name: "Academy",
        href: "/academy",
        icon: AcademicCapIcon,
        showUpgrade: auth.user().access_level < 2,
        current: routeName.value == "AcademyPage",
      },
      {
        name: "Bootcamp",
        href: "/bootcamp",
        icon: BootcampIcon,
        showUpgrade: auth.user().access_level < 1,
        current: routeName.value == "BootcampsPage",
        customIcon: true,
      },
    ]);

    const accountNavigation = [
      {
        name: "My Profile",
        href: `/user/${getUserlink()}`,
        icon: UserIcon,
        current: routeName.value == "UserProfilePage",
        disabled: auth.user().access_level < 1,
      },
      {
        name: "My Team",
        href: "/team",
        icon: UserGroupIcon,
        current: routeName.value == "Team",
        disabled: auth.user().access_level < 3,
      },
      {
        name: "Settings",
        href: "/settings",
        icon: CogIcon,
        current: routeName.value == "SettingsPage",
      },
    ]

    const externalNavigation = [
      {
        name: "How to use Refrag",
        href: "https://wiki.refrag.gg/",
        icon: BookOpenIcon,
        current: false,
      },
      {
        name: "Support",
        href: "https://discord.gg/refrag",
        icon: LifebuoyIcon,
        current: false,
      }
    ];

    function getUserlink() {
      if(auth.user().username) {
        return auth.user().username
      }
      return auth.user().uuid
    }

    function toggleAccountNav() {
      showAccountNav.value = !showAccountNav.value
    }

    function toggleSelectedGame(game) {
      selectedGame.value = game;
      updateSelectedGame();
    }

    function logout() {
      auth.logout({
        makeRequest: true,
        redirect: { name: "Login" },
      });
    }

    function seeNotification() {
      showNotifications.value = false;
      axios.post("/notifications/" + notifications.value[0].id + "/see")
    }

    function loadServers() {
      if(!store.state.servers) {
        axios.get(`server_locations`).then(({data}) => {
          processServersLatency(data);
        })
      }
    }

    loadServers();

    async function processServersLatency(servers) {
      try {
        const results = await Promise.allSettled(servers.map(measureLatency));

        store.commit("setServers", results.filter((result) => result.status === 'fulfilled').map((result) => result.value));
        results.filter((result) => result.status === 'rejected').forEach((result) => console.log(result.reason));
      } catch (error) {
          console.log("Could not ping server", error)
      }
    }

    async function measureLatency(server) {
      return new Promise(function(resolve, reject) {
        let startTime = Date.now();
        let socket = null;
        try {
          socket = new WebSocket('wss://' + server.value + '.dathost.net/console-server/');
        } catch (error) {
          return reject(error);
        }

        socket.onopen = () => {
          startTime = Date.now();
          const message = { cmd: 'datPing' };
          socket.send(JSON.stringify(message));
        };

        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
          if (response.cmd === 'datPong') {
            const endTime = Date.now();
            const timeDifference = endTime - startTime;
            resolve({value: server.value, ms: timeDifference, id: server.id, flag: server.flag, name: server.name});
          }

          socket.close();
          resolve(socket)
        };

        socket.onerror = function(err) {
          reject(err);
        };
      });
    }

    function loadSelectedGame() {
      if(selectedGame.value) {
        store.commit('setSelectedGame', selectedGame.value);
      } else {
        store.commit('setSelectedGame', 'cs2');
        selectedGame.value = 'cs2';
      }
      updateButtonColor();
      axios.defaults.headers.common['X-GAME'] = store.getters.selectedGame;
    }

    function updateSelectedGame() {
      loadSelectedGame();
      window.location.reload();
    }

    function updateButtonColor() {
      if(selectedGame.value == 'csgo') {
        var r = document.querySelector(':root');
        r.style.setProperty("--primary-600", "#687cca")
        r.style.setProperty("--primary-500", "#556cc6")
      } else {
        var r = document.querySelector(':root');
        r.style.setProperty("--primary-600", "#4E54C8")
        r.style.setProperty("--primary-500", "#474cba")
      }
    }

    function loadactiveTeam() {
      const user = auth.user();
      if (!localStorage.activeTeamId) {
        const activeTeam = user.teams[0];
        localStorage.activeTeamId = activeTeam.id;
        store.commit("setActiveTeam", activeTeam);
      } else {
        const activeTeam = user.teams.find(
          (team) => team.id == localStorage.activeTeamId
        );
        if (activeTeam === undefined) {
          store.commit("setActiveTeam", user.teams[0]);
        } else {
          store.commit("setActiveTeam", activeTeam);
        }
      }
      activeTeam.value = store.state.activeTeam
      loadModInfo();
    }

    function loadModInfo() {
      axios.get(`mods`).then(({data}) => {
        if(auth.user().access_level > 1) {
          store.commit("setModInfo", data);
        } else {
          let filteredMods = data.mods.filter(function( obj ) {
            return obj.name !== "Scrim";
          });
          let modInfo = { mods: [], tags: [] }
          modInfo.mods = filteredMods;
          modInfo.tags = data.tags;
          store.commit("setModInfo", modInfo);
        }
      })
    }

    function getLevelOneNotifications() {
      axios.get("/notifications?level=1")
        .then((response) => {
          if (response.data.length > 0) showNotifications.value = true;
          notifications.value = response.data;
        })
    }

    getLevelOneNotifications();

    function getRoute(item) {
      if (item.disabled) {
        return '#';
      }
      return item.href
    }

    function updateActiveTeam(team) {
      localStorage.activeTeamId = team.id;
      window.location.reload();
    }

    function toggleMobileMenu() {
      sidebarOpen.value = !sidebarOpen.value;
    }

    function updateSubMenuMargin(item) {
      if (!item.subMenu) return;
      item.subMenu.forEach((subItem, index) => {
        if (subItem.current) {
          subMenuSliderMargin.value = index * 32 + 'px';
        }
      });
    }

    watch(route, () => {
      navigation.value.forEach((item) => {
        if (item.subMenu && item.current) {
          item.subMenu.forEach((subItem) => {
            subItem.current = routePath.value == subItem.href;
            updateSubMenuMargin(item);
          });
        }
      });
    });

    navigation.value.forEach((item) => {
      if (item.subMenu && item.current) {
        updateSubMenuMargin(item);
      }
    });

    loadactiveTeam();
    loadSelectedGame();

    return {
      navigation,
      sidebarOpen,
      Logo,
      buttonLogo,
      logout,
      updateActiveTeam,
      externalNavigation,
      activeTeam,
      sideBarExtended,
      showAccountNav,
      toggleAccountNav,
      accountNavigation,
      toggleSelectedGame,
      selectedGame,
      getRoute,
      toggleMobileMenu,
      subMenuSliderMargin,
      notifications,
      showNotifications,
      seeNotification,
      showUpgradeModal,
    };
  },
  data: function () {
    return {
      showSteamConnectModal: false,
    };
  },
  props: ["title", "dark", "showSteamLogin", "iconsOnly", "noPadding"],
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    username() {
      if (this.user.username) {
        return this.user.username
      } else if (this.user.steam_profile) {
        return this.user.steam_profile.personaname;
      }
      return this.user.email;
    },
    imageURL() {
      if (this.user.steam_profile) {
        return this.user.steam_profile.avatar;
      }
      return buttonLogo;
    },
  },
  created() {
    if (!this.user.steam_id) this.showSteamConnectModal = true;
    if (this.showSteamLogin !== undefined)
      this.showSteamConnectModal = this.showSteamLogin;
  },
};
</script>

<style scoped>
.selected-nav {
  box-shadow: 2px 0 0 #4E54C8 inset;
}

.grow-transition {
  transition: width .3s;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  max-height: 230px;
  transition: all 0.15s ease-in;
}
.fadeHeight-enter-from,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
  transition: all 0.15s ease-in-out;
}

.boxshadow-r {
  box-shadow:  1px 0px 0px 0px #222C39;
}
</style>
