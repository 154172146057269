<template>
  <Dashboard>
    <div class="flex flex-col gap-12">
      <div v-if="user.match_token_outdated && !hideMatchTokenWarning" class="-mb-5 flex w-full bg-gray-800 rounded-lg px-3 py-3 gap-2">
        <img src="../assets/icons/error.svg" class="w-5 h-5">
        <h1 class="text-text-primary font-light text-sm flex-1">Your match token is outdated. Please update it by clicking <span class="underline cursor-pointer" @click="showUpdateMatchTokenModal = true">here.</span></h1>
        <button @click="hideWarning('match_token')" class="text-text-secondary text-sm hover:text-text-primary">Dismiss</button>
      </div>

      <div v-if="(!userSettings?.match_auth_token || userSettings?.match_auth_token === '********') && !hideAuthTokenWarning" class="-mb-5 flex w-full bg-gray-800 rounded-lg px-3 py-3 gap-2">
        <img src="../assets/icons/error.svg" class="w-5 h-5">
        <h1 class="text-text-primary font-light text-sm flex-1">Your steam auth code is missing. Please update it <span class="underline cursor-pointer" @click="showUpdateMatchAuthTokenModal = true">here</span> to get your most recent matches.</h1>
        <button @click="hideWarning('auth_token')" class="text-text-secondary text-sm hover:text-text-primary">Dismiss</button>
      </div>

      <CoachSection
        :user="user"
        :showMatches="showMatches"
        :userStatsHistory="userStatsHistory"
        @launchRecommendedMod="launchRecommendedMod"
        @startServer="updateModalServerInfo"
      />

      <div v-if="!userStats || userStatsHistory.error || user.access_level < 1" class="flex flex-col gap-6">
        <h1 class="text-text-primary font-semibold text-[24px]">Featured Modes</h1>

        <div class="grid grid-cols-1 xl:grid-cols-2 min-[1980px]:grid-cols-4 gap-6 items-center w-full">
          <div v-for="mod in featuredMods" class="bg-gray-900 rounded-lg p-4 flex-1 flex gap-4 col-span-1 flex-col lg:flex-row">
            <img :src="'/img/' + mod.name.toLowerCase() + '-image.jpg'" class=" flex-1 rounded-lg w-full lg:max-w-[260px]" />
            <div class="flex-1 flex flex-col gap-3">
              <h1 class="text-text-primary font-medium text-lg">{{ mod.name }}</h1>
              <p class="text-text-secondary font-light text-sm flex-1">
                {{ mod.description }}
              </p>
              <button
                @click="launchFeaturedMod(mod)"
                class="py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2"
              >
                Play now
                <svg
                  v-if="launchLoading === mod.id"
                  width="16"
                  height="16"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin"
                >
                  <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                  <path
                    d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                    stroke="#4E54C8"
                    stroke-width="2"
                  />
                </svg>
                <HandThumbUpIcon
                  v-if="launchSuccess === mod.id"
                  class="w-4 h-4 text-success-400"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <h1 class="text-text-primary font-semibold text-[24px]">What's New</h1>
        <div
          v-if="recentNews.length == 0"
          class="bg-gray-900 p-4 text-text-secondary text-xs tracking-[1.5px] w-full"
        >
          There's nothing new right now, check back later!
        </div>
        <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div
            v-for="(post, index) in recentNews"
            class="flex flex-col bg-gray-900 rounded-lg p-4 cols-span-1 w-full gap-6"
          >
            <img
              class="w-full aspect-[16/9] object-cover rounded-lg"
              :src="post.image_url"
            />
            <div class="flex flex-col gap-3 flex-1">
              <h2
                class="text-text-secondary font-light text-[10px] uppercase tracking-[1.5px]"
              >
                New {{ getContentTypeText(post?.content_type) }}
              </h2>
              <h1 class="text-text-primary font-medium text-lg">{{ post?.title }}</h1>
              <p class="text-text-secondary font-light text-sm flex-1">
                {{ post?.description }}
              </p>
            </div>
            <button
              @click="executePostAction(post)"
              class="py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 flex items-center justify-center gap-2"
              :class="[
                index === 0
                  ? 'bg-primary-600 hover:bg-primary-500'
                  : 'bg-gray-800 hover:bg-gray-700',
              ]"
            >
              {{ getButtonText(post) }}
              <svg
                v-if="launchLoading === post.id"
                width="16"
                height="16"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="animate-spin"
              >
                <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                <path
                  d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                  stroke="#4E54C8"
                  stroke-width="2"
                />
              </svg>
              <HandThumbUpIcon
                v-if="launchSuccess === post.id"
                class="w-4 h-4 text-success-400"
              />
            </button>
          </div>
        </div>
      </div>

      <div id="match-history" class="flex flex-col gap-6">
        <h1 class="text-text-primary font-semibold text-[24px]">Match History</h1>
        <div class="w-full flex rounded-lg flex-col gap-[2px] overflow-hidden">
          <div
            class="bg-gray-900 text-text-primary text-xs tracking-[1.5px] flex-1 p-4 uppercase flex md:gap-2 items-center"
          >
            <div class="flex-1 md:flex-none">Last {{ userStatsHistory?.length }} Matches</div>
            <span v-if="userStatsHistory.length > 0" class="hidden md:block text-text-secondary">•</span>
            <span
              v-if="userStatsHistory.length > 0"
              class="hidden md:block"
              :class="{
                'text-success-400': userWinPercentage > 50,
                'text-text-primary': userWinPercentage === 50,
                'text-danger-400': userWinPercentage < 50,
              }"
            >
              {{ userWinPercentage }}%
            </span>
            <h1 v-if="userStatsHistory.length > 0" class="flex-1 hidden md:block">Win Ratio</h1>
            <div v-else class="flex-1 hidden md:block"></div>
            <svg
              v-if="loadingMatches"
              width="16"
              height="16"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="animate-spin"
            >
              <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
              <path
                d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                stroke="#4E54C8"
                stroke-width="2"
              />
            </svg>
            <button
              @click="retrieveMatches()"
              class="bg-gray-800 rounded-lg py-2 px-3 text-text-primary text-xs hover:bg-gray-700 duration-300"
            >
              Get Recent Matches
            </button>
          </div>

          <div v-if="userStatsHistory.length > 0" v-for="match in userStatsHistory" class="bg-gray-900">
            <MatchRow :match="match" />
          </div>
          <div
            v-else
            class="bg-gray-900 p-4 text-text-secondary text-xs tracking-[1.5px]"
          >
            No Matches Found, make sure to add your Faceit ID and Steam tokens to your
            account in
            <router-link to="/settings" class="underline">settings!</router-link>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    :showMapSelector="showMapSelector"
    @close="showServerModal = false"
  />
  <MatchHighlightsModal
    :show="showMatchHighlightsModal"
    :clipId="selectedMatchHighlightId"
    @close="closeMatchHighlightModal()"
  >
  </MatchHighlightsModal>
  <UpdateMatchTokenModal
    :show="showUpdateMatchTokenModal"
    @close="showUpdateMatchTokenModal = false"
  />
  <UpdateMatchAuthTokenModal
    :show="showUpdateMatchAuthTokenModal"
    @close="showUpdateMatchAuthTokenModal = false"
  />
</template>

<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
import CoachSection from "../components/Home/CoachSection.vue";
import { ref, watch, computed, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { Chart, registerables } from "chart.js";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import MatchRow from "../components/UserProfile/MatchRow.vue";
import StartServerModal from "../components/StartServerModal.vue";
import { HandThumbUpIcon, ArrowRightIcon } from "@heroicons/vue/20/solid";
import Pill from "../components/Pill.vue";
import MatchHighlightsModal from "../components/MatchHighlightsModal.vue";
import { useHead } from "@unhead/vue";
import { formatStatString, toTitleCase, formatData, formatStatStringData } from "../utils/formatters";
import { createClickablePointsPlugin } from '../utils/chartPlugins';
import UpdateMatchTokenModal from "../components/UpdateMatchTokenModal.vue";
import UpdateMatchAuthTokenModal from "../components/UpdateMatchAuthTokenModal.vue";
const route = useRoute();
const router = useRouter();
const store = useStore();
const auth = useAuth();
const recentNews = ref([]);
const userRecentStats = ref(0);
const userWinPercentage = ref([]);
const showServerModal = ref(false);
const serverInfo = ref();
const activeServer = computed(() => store.getters.activeServer);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const matches = ref([]);
const userElos = ref(null);
const loadingMatches = ref(false);
const selectedMatchHighlightId = ref(null);
const showMatchHighlightsModal = ref(false);
const showMapSelector = ref(false);
const userStats = ref();
const userStatsHistory = ref([]);
const selectedStat = ref(null);
const statAverage = ref(0);
const mappedStats = ref([]);
const recommendedMap = ref(null);
const user = ref(auth.user());
const showUpdateMatchTokenModal = ref(false);
const mostPlayedMap = ref(null);
const totalMatchesCount = ref(0);
const totalPageCount = ref(0);
const userSettings = ref(null);
const allStats = ref([]);
const { proxy } = getCurrentInstance();
const cookies = proxy.$cookies;
const showUpdateMatchAuthTokenModal = ref(false);
const hideMatchTokenWarning = ref(!!getCookie('hide_match_token_warning'));
const hideAuthTokenWarning = ref(!!getCookie('hide_auth_token_warning'));

useHead({
  title: "Refrag | Dashboard",
});

Chart.register(...registerables);

// Create the plugin
const clickablePointsPlugin = createClickablePointsPlugin(
  (index) => userStatsHistory.value[userStatsHistory.value.length - 1 - index]?.match_id,
  (matchId) => router.push(`/matches/${matchId}`)
);

const showMatches = computed(() => {
  let show_matches = store.state.siteSettings?.find(
    (setting) => setting.name === "show_matches"
  )?.enabled;

  return show_matches;
});

// Register the plugin
Chart.register(...registerables, clickablePointsPlugin);

const featuredMods = computed(() => {
  return store.state.modInfo?.mods.filter((mod) => mod.featured);
});

onMounted(() => {
  if (route.query.game_mode) {
    handleGameModeQuery();
  }

  if (!cookies.get("has_logged_in")) {
    cookies.set("has_logged_in", true, "365d", null, ".refrag.gg");
  }
});

watch(() => store.getters.modInfo, (newModInfo) => {
  if (route.query.game_mode && !serverInfo.value) {
    handleGameModeQuery();
  }
}, { immediate: true });

function handleGameModeQuery() {
  if (!store.getters.modInfo?.mods) return;  // Exit if mods not loaded yet

  if (route.query.map) {
    const map = store.getters.modInfo.mods.find(
      (mod) => mod.name.toLowerCase() === route.query.game_mode.toLowerCase()
    )?.cs_maps.find(
      (m) => m.name.toLowerCase() === route.query.map.toLowerCase()
    );
    if (map) {
      serverInfo.value = {
        mode: route.query.game_mode,
        cs_map: map
      };
    }
  } else {
    serverInfo.value = {
      mode: route.query.game_mode,
    };
  }
  showMapSelector.value = true;
  showServerModal.value = true;
}

function launchRecommendedMod(mod, showMap = false, ttk = null, map = null) {
  if (showMap) {
    showMapSelector.value = true;
    serverInfo.value = {
      mode: mod,
      time_to_kill: ttk,
    };
    showServerModal.value = true;
  } else {
    showMapSelector.value = false;
    serverInfo.value = {
      mode: mod,
      cs_map: map || recommendedMap.value,
      time_to_kill: ttk,
    };
    showServerModal.value = true;
  }
}

function getRecentMatchStats() {
  axios.get(`/matches/recent_match_stats`, {
    params: {
      'steam_id': user.value.steam_id,
    },
  }).then((response) => {
    userStatsHistory.value = response.data?.matches || [];
    totalMatchesCount.value = response.data?.total_count || 0;
    totalPageCount.value = response.data.page_count || 0;
    calculateRecentStats();
    updateUserStatHistory(selectedStat.value);
  }).catch((error) => {

  });
}

watch(() => store.getters.modInfo, () => {
  if(recommendedMap.value) return;

  recommendedMap.value = store.getters.modInfo?.mods[0]?.cs_maps.find((map) => map.name === mostPlayedMap.value) || store.getters.modInfo?.mods[0]?.cs_maps[0];
});

getRecentMatchStats()

function launchFeaturedMod(mod) {
  if (!activeServer.value?.id) {
    launchLoading.value = null;
    showMapSelector.value = true;
    serverInfo.value = {
      mode: mod.name,
    };
    showServerModal.value = true;
    return;
  }

  if (activeServer.value.status !== "online") {
    store.commit("showMessage", {
      messageType: "error",
      messageText: "Please wait for the server to come online before launching a mode",
    });
    return;
  }

  if (!launchLoading.value) {
    launchLoading.value = mod.id;
  }
  axios({
    url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      command: "change_mod",
      value: mod.name,
    },
    headers: {
      "X-TEAM-ID": store.getters.activeTeam.id,
    },
  })
    .then(() => {
      launchSuccess.value = launchLoading.value;
      launchLoading.value = null;
      setTimeout(() => {
        launchSuccess.value = null;
      }, 3000);
    })
    .catch((err) => {
      launchLoading.value = null;
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not communicate with server",
      });
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
    });
}

function updateModalServerInfo(launchSettings) {
  serverInfo.value = launchSettings;
  showServerModal.value = true;
}

function closeMatchHighlightModal() {
  showMatchHighlightsModal.value = false;
}

function getModName(type) {
  if (type === "set") {
    return "Creator Studio";
  } else if (type === "grenade group") {
    return "Grenade Groups";
  } else if (type === "strategy") {
    return "Strategies";
  }
}

function launchPostContent(post, type) {
  let content = post.content;
  if (!activeServer.value?.id) {
    let launchSettings = {
      cs_map: content.cs_map,
      mode: getModName(type),
      content: content,
    };
    updateModalServerInfo(launchSettings);
    return;
  }

  let command = "";
  let value = "";

  if (type === "set") {
    command = "load_set";
    value = `custom_set, ${content.id}`;
  } else if (type === "grenade group") {
    command = "load_nadr";
    value = `grenades, ${content.id}`;
  } else if (type === "strategy") {
    command = "load_nadr";
    value = `strat, ${content.id}`;
  }

  if (activeServer.value.status !== "online") {
    store.commit("showMessage", {
      messageType: "error",
      messageText: "Please wait for the server to come online before launching a routine",
    });
    return;
  }

  launchLoading.value = post.id;

  axios({
    url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      command: command,
      value: value,
    },
    headers: {
      "X-TEAM-ID": store.getters.activeTeam.id,
    },
  })
    .then(() => {
      launchLoading.value = null;
      launchSuccess.value = post.id;
      setTimeout(() => {
        launchSuccess.value = null;
      }, 3000);
    })
    .catch((err) => {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not communicate with server",
      });
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
      launchLoading.value = null;
    });
}

function executePostAction(post) {
  switch (post.content_type) {
    case "feature":
      router.push(`${post.content_link}`);
      break;
    case "CustomSet":
      launchPostContent(post, "set");
      break;
    case "GrenadeGroup":
      launchPostContent(post, "grenade group");
      break;
    case "blog":
      window.open(post.content_link, "_blank");
      break;
    case "external":
      window.open(post.content_link, "_blank");
      break;
    default:
      break;
  }
}

function getButtonText(post) {
  switch (post.content_type) {
    case "feature":
      return "View Feature";
    case "CustomSet":
      return "Play Now";
    default:
      return "Read More";
  }
}

function getContentTypeText(type) {
  switch (type) {
    case "feature":
      return "Feature";
    case "external":
      return "Content";
    case "CustomSet":
      return "Featured Set";
    case "GrenadeGroup":
      return "Featured Grenade Group";
    case "blog":
      return "Blog";
    default:
      return "News";
  }
}

function retrieveMatches() {
  loadingMatches.value = true;
  axios
    .post("/matches/retrieve_my_matches")
    .then(() => {
      store.commit("showMessage", {
        messageType: "success",
        messageText: "Retrieving matches, this may take a while.",
      });
    })
    .catch(() => {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not retrieve matches, please try again later.",
      });
    })
    .finally(() => {
      loadingMatches.value = false;
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
    });
}

axios
  .get("/news_posts")
  .then((response) => {
    recentNews.value = response.data;
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("/matches/my_history")
  .then((response) => {
    matches.value = response.data;
    if (route.hash === "#matchhistory") {
      setTimeout(() => {
        document.getElementById("match-history").scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    }
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("/users/my_settings")
  .then((response) => {
    userSettings.value = response.data;
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("/recent_matches_stats")
  .then((response) => {
    userRecentStats.value = response.data;
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("statistics/my_elos")
  .then((response) => {
    userElos.value = response.data.elo_stats;
  })
  .catch((error) => {
    console.error(error);
  });

function calculateRecentStats() {
  const totalMatches = userStatsHistory.value.length;
  if (totalMatches === 0) return;

  const wins = userStatsHistory.value.filter(match => match.match_result === 1).length;

  userWinPercentage.value = ((wins / totalMatches) * 100).toFixed(0);
}

function updateUserStatHistory(stat) {
  let mappedStatHistory = userStatsHistory.value?.map((match) => {
    return formatData(match[selectedStat.value.title], stat.data.unit_type);
  });

  statAverage.value = formatData(stat.data.rating_range_average_value, stat.data.unit_type);
  mappedStats.value = mappedStatHistory;
}

function hideWarning(type) {
  const cookieName = `hide_${type}_warning`;
  cookies.set(cookieName, 'true', '30d');

  if (type === 'match_token') {
    hideMatchTokenWarning.value = true;
  } else if (type === 'auth_token') {
    hideAuthTokenWarning.value = true;
  }
}

function getCookie(name) {
  return cookies.get(name);
}

</script>

