<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="showSteamConnectModal">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-md px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          >
            <div>
              <div class="text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Please connect your steam account
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    We need your steam ID in order to whitelist you on our servers
                  </p>
                </div>
                <div class="mt-5">
                  <form action="https://steamcommunity.com/openid/login" method="get">
                    <input
                      type="hidden"
                      name="openid.identity"
                      value="http://specs.openid.net/auth/2.0/identifier_select"
                    />
                    <input
                      type="hidden"
                      name="openid.claimed_id"
                      value="http://specs.openid.net/auth/2.0/identifier_select"
                    />
                    <input
                      type="hidden"
                      name="openid.ns"
                      value="http://specs.openid.net/auth/2.0"
                    />
                    <input type="hidden" name="openid.mode" value="checkid_setup" />
                    <input
                      type="hidden"
                      name="openid.return_to"
                      :value="`https://${getEnvVar()}api.refrag.gg/auth/${$auth.user().sgid}/steam?redirect_url=https://${getEnvVar()}play.refrag.gg/upgrade`"
                    />
                    <input
                      type="hidden"
                      name="openid.realm"
                      :value="`https://${getEnvVar()}api.refrag.gg/auth/${$auth.user().sgid}/steam?redirect_url=https://${getEnvVar()}play.refrag.gg/upgrade`"
                    />
                    <button
                      type="submit"
                      class="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                    >
                      Connect your account
                    </button>
                  </form>
                </div>

                <div class="relative flex mt-5 items-center">
                  <div class="flex-grow border-t border-gray-200"></div>
                  <span class="flex-shrink mx-4 text-sm text-gray-400">OR</span>
                  <div class="flex-grow border-t border-gray-200"></div>
                </div>

                <div class="mt-2 text-center">
                  <p class="text-sm text-gray-500">
                    <button @click="logout" class="underline">Sign Out</button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { reactive } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import axios from "axios";
import { useAuth } from "@websanova/vue-auth/src/v3.js";


export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  setup() {
    const auth = useAuth();

    const state = reactive({});
    function startOmniauth() {
      axios.get("auth/steam");
    }

    function logout() {
      auth.logout({
        makeRequest: true,
        redirect: { name: "Login" },
      });
    }

    function getEnvVar() {
      if (import.meta.env.VITE_ENVIRONMENT) return import.meta.env.VITE_ENVIRONMENT
      return "";
    }

    return {
      state,
      startOmniauth,
      logout,
      getEnvVar,
    };
  },
  props: {
    showSteamConnectModal: Boolean,
  },
};
</script>
