<template>
  <Dashboard title="Custom Content Page" no-padding="true">
    <div v-if="!loading" class="w-full h-full flex flex-col relative">
      <div class="flex flex-col">
        <div class="flex relative flex-col">
          <div class="flex w-full px-9 py-12 z-20">
            <div
              class="flex flex-col lg:flex-row gap-y-3 w-full border-b pb-12 border-gray-800"
            >
              <div class="flex flex-col gap-3 flex-1">
                <div class="flex flex-row gap-4">
                  <h1 class="text-text-primary font-semibold text-4xl">
                    {{ bootcamp?.name }}
                  </h1>
                  <div
                    v-if="bootcamp?.coach_curated"
                    class="text-[10px] rounded-md px-1 py-[1px] mr-1 self-start"
                    style="
                      background-color: rgba(208, 113, 197, 0.125);
                      color: rgb(208, 113, 197);
                    "
                  >
                    Coach
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <h2 class="text-text-secondary text-sm font-light">
                    {{ bootcamp?.description }}
                  </h2>
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <button
                  :disabled="!activeServer"
                  @click="launchRoutine()"
                  :class="[
                    !activeServer
                      ? 'bg-gray-600'
                      : 'bg-primary-600 hover:bg-primary-500 duration-300',
                  ]"
                  class="w-full has-tooltip lg:w-auto text-text-primary rounded-lg flex gap-2 py-2 items-center justify-center"
                >
                  <span
                    v-if="!activeServer"
                    href="https://discord.gg/Refrag"
                    class="tooltip rounded shadow-md p-1 mt-5 bg-gray-900 text-white top-16 min-w-32"
                    >Please start a server to launch a set</span
                  >
                  <svg
                    v-if="launchLoading"
                    class="animate-spin h-full font-normal w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <HandThumbUpIcon v-else-if="launchSuccess" class="w-3 h-3 text-green-500" />
                  <img
                    v-else
                    src="../../assets/icons/play.svg"
                    alt="play"
                    class="w-3 h-3 brightness-200"
                  />
                  <h1 class="text-xs">Launch Routine</h1>
                </button>
                <div class="flex gap-3">
                  <button
                    v-if="bootcamp?.author.id === user.id && !bootcamp?.coach_curated"
                    @click="router.push(`/routines/${bootcamp?.slug}/edit`)"
                    class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center"
                  >
                    <h1 class="text-xs">Edit</h1>
                  </button>
                  <button
                    v-else-if="bootcamp?.coach_curated"
                    @click="cloneRoutine()"
                    class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center"
                  >
                    <h1 class="text-xs">Clone</h1>
                  </button>
                  <button
                    v-else-if="bootcamp?.enrolled"
                    class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center"
                  >
                    <CheckIcon class="w-3 h-3 text-text-primary" />
                    <h1 class="text-xs">Subscribed</h1>
                  </button>
                  <button
                    @click="subscribe"
                    v-else
                    class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center"
                  >
                    <PlusCircleIcon class="w-3 h-3 text-text-primary" />
                    <h1 class="text-xs">Subscribe</h1>
                  </button>
                  <button
                    class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center"
                    v-clipboard:copy="`http://${url}/routines/${bootcamp?.slug}`"
                    v-clipboard:success="copySucceeded"
                  >
                    <img
                      src="../../assets/icons/copy.svg"
                      alt="subscribe"
                      class="w-3 h-3 brightness-200"
                    />
                    <h1 class="text-xs">Share</h1>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full px-9 z-20">
            <div class="w-full border-b border-gray-800">
              <div class="flex flex-col lg:flex-row gap-12 pb-12 items-start">
                <div class="flex-1 gap-4 flex flex-col">
                  <h2 class="text-text-primary text-lg font-semibold">
                    Segments - {{ tasks.length }}
                  </h2>
                  <div class="flex flex-col gap-[2px] w-full">
                    <div
                      v-for="(task, index) in tasks.slice(0, showTasksInt)"
                      class="item p-4 flex items-center gap-1 select-none bg-gray-900"
                      :key="task"
                    >
                      <div
                        class="flex items-center justify-center h-10 w-10 rounded-full bg-gray-800 border border-gray-700 text-sm text-text-primary"
                      >
                        #{{ index + 1 }}
                      </div>
                      <div class="flex flex-col justify-center gap-1 pl-2">
                        <h2 class="text-text-primary">{{ task.name }}</h2>
                        <div class="text-text-secondary flex items-center text-xs gap-2">
                          <h2>{{ task.cs_map.friendly_name }}</h2>
                          <span class="text-text-disabled">•</span>
                          <h2>{{ task.mod }}</h2>
                          <span class="text-text-disabled">•</span>
                          <h2>{{ task.estimated_duration_in_minutes || 0 }} mins</h2>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="showTasksInt < tasks.length"
                      class="item p-2 flex items-center gap-1 select-none bg-gray-900 fadeOut"
                    ></div>
                  </div>
                  <div v-if="showTasksInt < tasks.length">
                    <button
                      @click="showTasksInt += 2"
                      class="w-full text-text-primary bg-gray-800 rounded-lg flex gap-2 py-3 px-3 items-center justify-center"
                    >
                      <h1 class="text-xs">Show More</h1>
                    </button>
                  </div>
                  <div v-else-if="showTasksInt > 4">
                    <button
                      @click="showTasksInt = 4"
                      class="w-full text-text-primary bg-gray-800 rounded-lg flex gap-2 py-3 px-3 items-center justify-center"
                    >
                      <h1 class="text-xs">Show Less</h1>
                    </button>
                  </div>
                </div>
                <div class="grid gap-8 flex-1">
                  <h2 class="text-text-primary text-lg font-semibold">Details</h2>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Author</h3>
                    <h3 class="text-text-primary text-sm font-semibold">
                      {{ username() }}
                    </h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Length</h3>
                    <h3 class="text-text-primary text-sm font-semibold">
                      {{ bootcamp?.total_time_estimation }} minutes
                    </h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">
                      Subscriptions
                    </h3>
                    <h3 class="text-text-primary text-sm font-semibold">
                      {{ bootcamp?.students_count }}
                    </h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">
                      Created At
                    </h3>
                    <h3 class="text-text-primary text-sm font-semibold">
                      {{ moment(bootcamp?.created_at).format("LLL") }}
                    </h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">
                      Last Updated
                    </h3>
                    <h3 class="text-text-primary text-sm font-semibold">
                      {{ moment(bootcamp?.updated_at).format("LLL") }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    @close="showServerModal = false"
  />
</template>

<script setup>
import axios from "axios";
import StartServerModal from "../../components/StartServerModal.vue";
import { ref, computed, watch } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import { CheckIcon, PlayIcon, PlusCircleIcon, HandThumbUpIcon } from "@heroicons/vue/20/solid";
import { useHead } from "@unhead/vue";

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const user = ref(auth.user());
const store = useStore();
const bootcamp = ref(null);
const loading = ref(true);
const activeServer = computed(() => store.getters.activeServer);
const settings = computed(() => store.getters.siteSettings);
const serverInfo = ref(null);
const showServerModal = ref(false);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const tasks = ref([]);
const selectedSegment = ref(null);
const showTasksInt = ref(4);
const url = ref(location.host);

useHead({
  title: () =>
    bootcamp.value?.name ? `Refrag | ${bootcamp.value?.name}` : "Refrag | Routine",
  description:
    "Play a mixture of community created and pro warmup and training routines using Refrag's routines feature.",
});

function cloneRoutine() {
  axios({
    url: `/task_lists/${bootcamp.value.slug}/clone`,
    method: "POST",
  }).then((res) => {
    store.commit("showMessage", {
      messageType: "success",
      messageText: "Routine Cloned Successfully",
    });
    router.push(`/routines/${res.data.slug}`);
    bootcamp.value = res.data;
  })
  .catch((err) => {
    if (err.response.data.message === "You already have a clone of this version") {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "You have already cloned this routine",
      });
    } else {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not clone routine",
      });
    }
  });
}

function getBootcamp() {
  loading.value = true;
  axios({
    url: `/task_lists/${route.params.slug}`,
    method: "GET",
    params: {
      is_exam: false,
    },
  }).then((response) => {
    bootcamp.value = response.data;
    loadTasks();
    selectedSegment.value = tasks.value[0];
  });
}

function copySucceeded() {
  store.commit("showMessage", {
    messageType: "success",
    messageText: "Link Copied Successfully",
  });
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
}

function subscribe() {
  axios({
    url: `/task_lists/${bootcamp.value.slug}/enroll`,
    method: "POST",
  }).then(() => {
    store.commit("showMessage", {
      messageType: "success",
      messageText: "Subscribed Successfully",
    });
    bootcamp.value.enrolled = true;
  });
}

function loadTasks() {
  bootcamp.value.sections.forEach((section) => {
    section.tasks.forEach((task) => {
      tasks.value.push(task);
    });
  });
  loading.value = false;
}

function username() {
  if (bootcamp.value?.author.username) {
    return bootcamp.value?.author?.username;
  }
  return bootcamp.value?.author?.steam_profile?.personaname;
}

function launchRoutine() {
  let task = bootcamp.value.sections[0].tasks[0];
  if (!activeServer.value?.id) {
    serverInfo.value = {
      cs_map: task.cs_map,
      mode: task.mod,
      content: {
        id: parseInt(task.id),
        title: task.name,
        type: "task",
        task_list_id: bootcamp.value.id,
        task_list_section_id: task.section_id,
        task_list_difficulty: 0,
        is_exam: false,
      },
    };
    showServerModal.value = true;
    return;
  }

  if (activeServer.value.status !== "online") {
    store.commit("showMessage", {
      messageType: "error",
      messageText: "Please wait for the server to come online before launching a routine",
    });
    return;
  }

  launchLoading.value = true;
  axios({
    url: `/cs_servers/${activeServer.value?.id}/exec_server_command`,
    method: "POST",
    timeout: 5000,
    params: {
      command: "launch_task",
      value: `${bootcamp.value.id}, ${task.section_id}, ${task.id}, 0, 0`,
      is_client_command: false,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  })
    .then(() => {
      launchLoading.value = false;
      launchSuccess.value = true;
      setTimeout(() => {
        launchSuccess.value = false;
      }, 2000);
    })
    .catch((err) => {
      launchLoading.value = false;
      store.commit("showMessage", {
        messageType: "error",
        messageText: `Could not execute server command!`,
      });
    });
}

getBootcamp();
</script>
<style>
.fadeOut {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
}
</style>
