<template>
<div class="flex flex-col w-full max-w-5xl">
    <div class="w-full flex flex-col mb-8 lg:px-40">
        <div class="flex flex-col bg-gray-600 rounded-md w-full">
            <div class="relative flex w-full bg-gray-800 rounded-md p-1">
                <button type="button" class="flex-1 border border-transparent rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none" :class="[
                            interval == 1 ? 'bg-gray-700 shadow-sm text-text-primary' : 'text-text-secondary',
                        ]" @click="setInterval(1)">
                    Monthly
                </button>
                <button type="button" class="flex-1 border border-transparent rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none" :class="[
                            interval == 3 ? 'bg-gray-700 shadow-sm text-text-primary' : 'text-text-secondary',
                        ]" @click="setInterval(3)">
                    3-Monthly
                </button>
                <button type="button" class="flex-1 border border-transparent rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none" :class="[
                            interval == 6 ? 'bg-gray-700 shadow-sm text-text-primary' : 'text-text-secondary',
                        ]" @click="setInterval(6)">
                    6-Monthly
                </button>
                <button type="button" class="flex-1 border border-transparent rounded-md py-2 text-sm flex items-center justify-center gap-2 font-medium whitespace-nowrap focus:outline-none" @click="setInterval(12)" :class="[
                        interval == 12 ? 'bg-gray-700 shadow-sm text-text-primary' : 'text-text-secondary',
                    ]">
                    Yearly
                    <Pill class="px-2 py-[2px]" text="-20%" bgColor="#6A7DFF1A" textColor="#6A7DFF" />
                </button>
            </div>
        </div>
    </div>
    <!-- Tiers -->
    <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 lg:max-w-none max-w-md m-auto">
        <div v-for="tier in tiers" class="w-full flex-col flex rounded-md border border-gray-800 p-6 justify-between" :class="[tier.mostPopular ? '' : 'mt-10']">
            <div class="flex items-center">
                <h1 class="text-text-primary text-xl font-medium flex-1">{{ tier.name }}</h1>
                <Pill v-if="tier.mostPopular" class="px-2 py-[2px]" text="Most Popular" bgColor="#6A7DFF1A" textColor="#6A7DFF" />
                <Pill v-if="tier.isNew" class="px-2 py-[2px]" text="New" bgColor="#14B8A620" textColor="#14B8A6" />
            </div>
            <h1 class="text-text-primary font-bold text-[32px] pt-5">
                ${{ tier.price(interval) }}
                <span class="text-text-secondary text-xl font-medium">/ month</span>
            </h1>
            <div class="w-full h-px bg-gray-800 my-6" />
            <h2 class="text-text-primary text-sm font-medium pb-5">{{ tier.description }}</h2>
            <ul class="flex flex-col gap-4 pb-11 flex-1">
                <li v-for="feature in getTierFeaturedFeatures(tier.name.toLocaleLowerCase())" class="gap-2 flex items-center">
                    <CheckIcon class="h-5 w-5 text-primary-600" />
                    <h2 class="text-text-secondary text-sm">{{ feature }}</h2>
                </li>
            </ul>
            <div class="flex gap-2 items-center justify-center">
                <img class="h-5 w-5" src="../assets/icons/bigserver.svg">
                <h2 class="text-text-primary text-sm">On Demand Server<span v-if="tier.name === 'Teams'"> x7</span></h2>
            </div>
            <button :disabled="isCurrentPlanLower(tier.planId())" @click="subscribe(tier.planId())" v-if="!tier.priceIsHidden" :class="[tier.mostPopular
                        ? 'bg-primary-600 hover:bg-primary-500'
                        : 'bg-gray-700 hover:bg-gray-600',
                        'has-tooltip disabled:cursor-not-allowed disabled:bg-gray-800 disabled:text-gray-500 mt-8 flex gap-2 items-center justify-center w-full py-3 px-3 border text-white border-transparent rounded-md text-center font-medium',
                        ]">
                Subscribe
                <img src="../assets/icons/rightarrow.svg">
                <div v-if="isCurrentPlanLower(tier.planId())" class="tooltip bg-gray-900 rounded-lg p-2 ml-8 -mt-20">
                    <p class="text-text-primary text-xs font-light">
                        You are already have a higher tier plan then this one.
                    </p>
                </div>
            </button>
        </div>
    </div>
    <table v-if="featuresExpanded" class="h-px w-full table-fixed mt-20 mb-5">
        <thead>
            <tr>
                <th class="px-6 pb-4 text-left text-sm font-medium text-white" scope="col">
                    <span class="sr-only">Feature by</span>
                </th>
                <th v-for="tier in tiers" :key="tier.name" class="w-1/4 px-6 pb-4 text-left text-sm lg:text-xl font-medium leading-6 text-white" scope="col">
                    {{ tier.name }}
                    <button @click="subscribe(tier.planId())" v-if="!tier.priceIsHidden" :class="[tier.mostPopular
                                ? 'bg-primary-600 hover:bg-primary-500'
                                : 'bg-gray-700 hover:bg-gray-600',
                                'hidden lg:flex mt-8 gap-2 items-center justify-center w-full py-3 px-3 border text-text-primary text-sm border-transparent rounded-md text-center font-medium',
                                ]">
                        Subscribe
                        <img src="../assets/icons/rightarrow.svg">
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-for="section in sections" :key="section.name">
                <tr>
                    <th class="py-3 pl-6 text-left text-[10px] lg:text-sm font-medium text-text-primary" colspan="4" scope="colgroup">{{ section.name }}</th>
                </tr>
                <tr v-for="feature in section.features" :key="feature.name" class="divide-y divide-gray-800">
                    <th class="py-5 px-6 text-left text-[10px] lg:text-sm font-light text-text-secondary" scope="row"><a v-bind:href="feature.href">{{ feature.name }} <span v-if="!feature.cs2"> (soon)</span></a></th>
                    <td v-for="tier in tiers" :key="tier.name" class="py-5 px-6">
                        <span v-if="typeof feature.tiers[tier.name] === 'string'" class="block text-[10px] lg:text-sm text-white">{{ feature.tiers[tier.name] }}</span>
                        <template v-else>
                            <CheckIcon v-if="feature.tiers[tier.name] === true" class="h-5 w-5 text-white" aria-hidden="true" />
                            <MinusIcon v-else class="h-5 w-5 text-white" aria-hidden="true" />
                            <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Included' : 'Not included' }} in {{ tier.name }}</span>
                        </template>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>

    <div @click="showAllFeaturesToggle()" class="cursor-pointer border border-gray-800 mt-4 justify-center items-center rounded-md">
        <h1 class="py-3 text-sm text-text-primary font-satoshi text-center m-auto">
            <h1 v-if="featuresExpanded">Show Less</h1>
            <h1 v-else>View All Features</h1>
        </h1>
    </div>
</div>
</template>

<script>
import axios from "axios";
import {
    CheckIcon,
    MinusIcon
} from "@heroicons/vue/24/outline";
import {
    mapMutations
} from "vuex";
import Pill from "./Pill.vue";

export default {
    components: {
        CheckIcon,
        Pill
    },
    data() {
        return {
            interval: 1,
            featuresExpanded: false,
        };
    },
    methods: {
        ...mapMutations(["showMessage"]),
        setInterval(interval) {
            this.interval = interval;
        },
        showAllFeaturesToggle() {
            this.featuresExpanded = !this.featuresExpanded
        },
        subscribe(planId) {
            this.$gtm.push({
                event: 'begin_checkout',
                planId: planId,
                value: this.tiers.find(tier => tier.planId() === planId).price(),
                quantity: 1
            })
            axios
                .get(`/xsolla/start_checkout?plan_id=${planId}`)
                .then(({
                    data
                }) => {
                    window.location = data["checkout_url"];
                    this.updateOnboardingStepsCompleted();
                })
                .catch(() => {
                    console.log("Could not get checkout URL");
                });
        },
        getTierFeaturedFeatures(tier) {
            switch (tier) {
                case 'player':
                    return ["Coach", "Crossfire", "Prefire", "NADR", "Recoil Trainer", "Creator Studio", "Routines", "Bootcamp", "& more"];
                case 'competitor':
                    return ["Scrim", "Utility Hub", "Academy", "2D Demo Viewer"];
                case 'teams':
                    return ["Team Access", "7 Player Slots", "7 Individual Servers"];
            }
        },
        updateOnboardingStepsCompleted() {
            if(this.user.onboarded_steps_completed >= 5) return;
            axios({
                url: "/users/update_user",
                method: "PUT",
                params: {
                    onboarded_steps_completed: 4
                }
            })
        },
        isCurrentPlanLower(targetPlanId) {
            // Get all tiers with their plans in order
            const tierPlans = this.tiers.map(tier => tier.plans);
            
            // Flatten into a single array to maintain the correct order
            const orderedPlans = tierPlans.flat();
            
            // Get indices for comparison
            const targetIndex = orderedPlans.indexOf(targetPlanId);
            const currentIndex = orderedPlans.indexOf(this.user.active_subscription_plan_id);
            
            // If current plan is empty/null/undefined, return false
            if (!this.user.active_subscription_plan_id) return false;
            
            // If current plan not found but not empty, treat as lowest tier
            if (currentIndex === -1) return true;
            
            // Return true if current plan index is lower than target plan index
            return currentIndex >= targetIndex;
        },
    },
    watch: {
        autoRenew(value) {
            if (!value && this.interval == 1) {
                this.interval = 3;
            }
        },
    },
    computed: {
        user() {
            return this.$auth.user() || {};
        },
        billing_text() {
            switch (this.interval) {
                case 1:
                    return "month";
                case 3:
                    return "3 months";
                case 6:
                    return "6 months";
                case 12:
                    return "year";
                default:
                    7;
            }
            return null;
        },
        sections() {
            return [
                {
                    name: "Strategy",
                    features: [{
                            name: 'Utility Hub',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: false,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: '2D Demo Viewer',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: false,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Academy',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: false,
                                Competitor: true,
                                Teams: true
                            }
                        },
                    ]
                },
                {
                    name: 'Access',
                    features: [{
                            name: 'Number of Players',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: '1 Account',
                                Competitor: '1 Account',
                                Teams: '7 Accounts'
                            }
                        },
                        {
                            name: 'Game Servers',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: 'On Demand',
                                Competitor: 'On Demand',
                                Teams: 'On Demand for every player'
                            }
                        },
                        {
                            name: 'Team Access',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: false,
                                Competitor: false,
                                Teams: true
                            }
                        }
                    ],
                },
                {
                    name: "Practice",
                    features: [
                        {
                            name: 'Coach',
                            cs2: true,
                            href: 'https://refrag.gg/coach',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Bootcamp',
                            cs2: true,
                            href: 'https://refrag.gg/blog/announcing-bootcamp-cs2-training-made-easy',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Routines',
                            cs2: true,
                            href: 'https://refrag.gg/blog/update-create-your-own-routines-in-refrag',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'NADR',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Spray Transfer',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },

                        {
                            name: 'Detailed Aim Stats',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: false,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Recoil Trainer',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                    ],
                },
                {
                    name: "Warmup",
                    features: [{
                            name: 'Crossfire',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Prefire',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Versus Play List',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: '3 Players',
                                Competitor: '5 Players',
                                Teams: '5 Players'
                            }
                        },
                        {
                            name: 'Defender',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Xfire',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Clutch',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Blitz',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Repeek',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Creator Studio',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Waves',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Warmup',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                    ]
                },
                {
                    name: "Multiplayer",
                    features: [{
                            name: 'Scrim',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: false,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Duels',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Retakes',
                            cs2: true,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                        {
                            name: 'Executes',
                            cs2: false,
                            href: 'https://wiki.refrag.gg/',
                            tiers: {
                                Player: true,
                                Competitor: true,
                                Teams: true
                            }
                        },
                    ]
                }
            ]
        },
        tiers() {
            return [{
                    name: "Player",
                    price: () => {
                        switch (this.interval) {
                            case 1:
                                return 7;
                            case 3:
                                return 6;
                            case 6:
                                return 5.8;
                            case 12:
                                return 5.4;
                            default:
                                7;
                        }
                    },
                    description: "Player includes:",
                    cta: "Subscribe now",
                    mostPopular: false,
                    isNew: false,
                    plans: ["tOYAn6XO", "A1CtCuVE", "iMQBKlxk", "Q4LH0if3"],
                    planId: () => {
                        switch (this.interval) {
                            case 1:
                                return "tOYAn6XO";
                            case 3:
                                return "A1CtCuVE";
                            case 6:
                                return "iMQBKlxk";
                            case 12:
                                return "Q4LH0if3";
                            default:
                                7;
                        }
                    },
                },
                {
                    name: "Competitor",
                    price: () => {
                        switch (this.interval) {
                            case 1:
                                return 15;
                            case 3:
                                return 12.3;
                            case 6:
                                return 12;
                            case 12:
                                return 11.5;
                            default:
                                15;
                        }
                    },
                    description: "Everything in Player, Plus:",
                    cta: "Subscribe now",
                    mostPopular: true,
                    isNew: false,
                    plans: ["xvWlkb3W", "zt9mXCsq", "iTr5rrA9", "hSripLw8"],
                    planId: () => {
                        switch (this.interval) {
                            case 1:
                                return "xvWlkb3W";
                            case 3:
                                return "zt9mXCsq";
                            case 6:
                                return "iTr5rrA9";
                            case 12:
                                return "hSripLw8";
                            default:
                                7;
                        }
                    },
                },
                {
                    name: "Teams",
                    price: () => {
                        switch (this.interval) {
                            case 1:
                                return 79;
                            case 3:
                                return 66;
                            case 6:
                                return 63;
                            case 12:
                                return 61;
                            default:
                                60;
                        }
                    },
                    description: "Everything in Competitor, Plus:",
                    cta: "Subscribe now",
                    mostPopular: false,
                    isNew: true,
                    plans: ["0iEI5P4r", "IIblZHNc", "OcayLdVB", "IHlR8TQF"],
                    planId: () => {
                        switch (this.interval) {
                            case 1:
                                return "0iEI5P4r";
                            case 3:
                                return "IIblZHNc";
                            case 6:
                                return "OcayLdVB";
                            case 12:
                                return "IHlR8TQF";
                            default:
                                7;
                        }
                    },
                },
            ];
        },
    },
};
</script>
